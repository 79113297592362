<template>
  <flex column class="map height-100">
    <flex column>
      <div v-show="!isOpenFilters && !isOpenSearch && !isObjectGuid">
        <div id="show-controls">
          <map-panel
            :area="areaId"
            :sections="tree"
            @filtersmap="filtersMap"
            @searchmap="isOpenSearch = 1"
            @legendMap="
              $router.push({
                path: $route.path,
                query: {
                  districtId: districtId,
                  landId: currentLandId
                }
              })
            "
            @openObject="openObjectMap($event)"
          />
        </div>
      </div>
      <div v-show="isOpenSearch">
        <map-search
          @close="$router.back()"
          @openObjectMap="openObjectMap($event)"
        ></map-search>
      </div>
    </flex>

    <flex v-show="!isObjectGuid" fill>
      <yandex-maps
        v-if="
          !loadingAreas &&
            !loadingObjects &&
            !loadingDistricts &&
            !loadingSections &&
            !isOpenFilters &&
            !isOpenLegend &&
            !isOpenSearch
        "
      >
        <yandex-map
          :height="'100vh'"
          :center="mapState.center"
          :zoom="mapState.zoom"
          :controls="[]"
          :behaviors="['drag', 'scrollZoom']"
        >
          <yandex-geolocation
            class="geolocation-button font-color-gray shadow"
            provider="browser"
            :position="{ top: '20px', left: '30px' }"
            :options="{ mapStateAutoApply: true }"
          >
            <icon name="geolocation" :size="30" />
          </yandex-geolocation>
          <zoom-map :position="{ bottom: '90px', left: '30px' }" />

          <yandex-object-manager
            :areaId="areaId"
            ballon
            :entity="entity"
            :districtId="districtId"
            fitToViewport
            @addPoint="addPoint($event)"
            @click-dict="onClickPolygon"
            :items="itemsCurrent"
          />

          <yandex-object-manager
            :entity="entity"
            :districtId="districtId"
            fitToViewport
            :items="itemsGeoObjects.unlegal"
            @click="onClickPoint"
            :options="{
              clusterize: true,
              maxZoom: 16,
              clusterIconLayout: 'cluster#unlegal',
              clusterIconShape: {
                type: 'Circle',
                coordinates: [0, 0],
                radius: 20
              }
            }"
          />

          <yandex-object-manager
            :entity="entity"
            :districtId="districtId"
            fitToViewport
            :items="itemsGeoObjects.legal_after"
            @click="onClickPoint"
            :options="{
              clusterize: true,
              maxZoom: 16,
              clusterIconLayout: 'cluster#legal_after',
              clusterIconShape: {
                type: 'Circle',
                coordinates: [0, 0],
                radius: 20
              }
            }"
          />

          <yandex-object-manager
            :entity="entity"
            :districtId="districtId"
            fitToViewport
            :items="itemsGeoObjects.legal_before"
            @click="onClickPoint"
            :options="{
              clusterize: true,
              maxZoom: 16,
              clusterIconLayout: 'cluster#legal_before',
              clusterIconShape: {
                type: 'Circle',
                coordinates: [0, 0],
                radius: 20
              }
            }"
          />

          <yandex-layout-template
            storage-key="cluster#legal"
            :template="
              `<div class='cluster_icon_container'>
                                <div class='cluster_icon cluster_icon-legal_before'>{{ properties.geoObjects.length }}</div>
                            </div>`
            "
          />

          <yandex-layout-template
            storage-key="cluster#unlegal"
            :template="
              `<div class='cluster_icon_container'>
                                <div class='cluster_icon cluster_icon-unlegal'>{{ properties.geoObjects.length }}</div>
                            </div>`
            "
          />

          <yandex-layout-template
            storage-key="cluster#legal_before"
            :template="
              `<div class='cluster_icon_container'>
                                <div class='cluster_icon cluster_icon-legal_before'>{{ properties.geoObjects.length }}</div>
                            </div>`
            "
          />

          <yandex-layout-template
            storage-key="cluster#legal_after"
            :template="
              `<div class='cluster_icon_container'>
                                <div class='cluster_icon cluster_icon-legal_after'>{{ properties.geoObjects.length }}</div>
                            </div>`
            "
          />

          <yandex-layout-template
            storage-key="placemark#violation"
            :template="
              `<div class='relative'><div class='absolute violation-icon property-{{ properties.property_type_id }} violation-{{ properties.violation_type_id }} {% if properties.active %}active{% endif %}' style='top: -10px; left: -10px'></div></div>`
            "
          />
        </yandex-map>
      </yandex-maps>

      <div id="eventMap"></div>
    </flex>
  </flex>

  <map-filters
    @show="$router.back()"
    @filter="applyFilter"
    v-if="isOpenFilters"
  ></map-filters>

  <object-form
    :dataParams="dataParams"
    v-if="isOpenForm"
    @formmap="closeForm($event, -1)"
    @saved="closeForm($event, -3)"
    :entity="entity"
    :guid="object.guid"
  ></object-form>

  <object-card
    @close="closeCard(), filtersMap($event)"
    @edit="editObj($event)"
    :entity="entity"
    :guid="object.guid"
    v-if="isObjectGuid && !isOpenForm"
  ></object-card>
</template>

<script>
import { onMounted, computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import useLands from '@/composition/useLands';
import useObjects from '@/composition/useObjects';
import useDefaultValues from '@/composition/useDefaultValues';

import useDistricts from '@/composition/useDistricts';

import { defaultMapValues } from '@/components/yandex-map/data/defaultValues';

import useObjectsTree from '@/composition/map/useObjectsTree';
import useAreas from '@/composition/map/useAreas';

import { eventObjValue } from '@/components/yandex-map/layouts/BalloonAppend';
import mapHelpers from '@/composition/map/mapHelpers';
import logic from '@/data/logic';

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();

    const isObjectGuid = computed({
      get() {
        return route.query.guid || null;
      },
      set(value) {
        router.push({
          path: route.path,
          query: {
            ...route.query,
            guid: value
          }
        });
      }
    });

    const mapState = ref({
      center: [56.229398, 58.024384],
      zoom: 9,
      polygon: null
    });
    const dataParams = ref({});

    let path = route.path.split('/');
    const entity = path.length ? path[1] : '';
    const lastEntity = ref(null);

    const isOpenFilters = computed({
      get() {
        return Boolean(route.query.filters);
      },
      set(value) {
        router.push({
          path: route.path,
          query: {
            ...route.query,
            filters: value
          }
        });
      }
    });

    const editObj = v => {
      dataParams.value.section = v.section;
      object.value.guid = v.guid;
      //isObjectGuid.value = null;
      isOpenForm.value = 1;
    };

    const isOpenForm = computed({
      get() {
        return Boolean(route.query.form);
      },
      set(value) {
        router.push({
          path: route.path,
          query: {
            ...route.query,
            object: 0,
            form: value
          }
        });
      }
    });

    const isOpenLegend = computed({
      get() {
        return Boolean(route.query.legend);
      },
      set(value) {
        router.push({
          path: route.path,
          query: {
            ...route.query,
            legend: value
          }
        });
      }
    });

    const isOpenSearch = computed({
      get() {
        return Boolean(route.query.search);
      },
      set(value) {
        router.push({
          path: route.path,
          query: {
            ...route.query,
            search: value
          }
        });
      }
    });

    const { defaultValues } = useDefaultValues();

    const { currentLandId } = useLands();

    // const { defaultStatusId } = useStatusList();

    onMounted(async () => {
      document.getElementById('eventMap').addEventListener('click', () => {
        if (eventObjValue == 'main') {
          currentLandId.value = logic.PROPERTY_TYPE_PUBLIC;
        } else {
          currentLandId.value = logic.PROPERTY_TYPE_PRIVATE;
        }
        openForm();
        mapState.value.center = mapState.value.lastCenter;
      });
    });

    const landId = computed({
      get() {
        return parseInt(route.query.landId) || defaultValues.value.LAND_ID;
      },
      set(value) {
        router.push({
          path: route.path,
          query: {
            ...route.query,
            landId: value
          }
        });
      }
    });

    const areaId = computed(
      () => parseInt(route.query.areaId) || defaultValues.value.AREA_ID
    );
    const districtId = computed(() => parseInt(route.query.districtId) || null);

    const { objects, loading: loadingObjects } = useObjects();

    const {
      tree,
      load: loadSections,
      loading: loadingSections
    } = useObjectsTree();

    let legendList = ref(tree);

    const {
      getAreaById,
      currentDistrict,
      loading: loadingDistricts,
      load: loadDistricts
    } = useDistricts();

    const { areas, loading: loadingAreas, load: loadAreas } = useAreas();
    const object = ref({});
    object.value.entity = entity;

    if (isObjectGuid.value) {
      new Promise(resolve => {
        currentLandId.value = landId.value;
        resolve();
      }).then(() => {
        setTimeout(() => {
          object.value.guid = isObjectGuid.value;
        }, 100);
      });
    }

    const closeCard = () => {
      if (route.query.legend) {
        router.go(-1);
      } else {
        router.go(-1);
      }
    };

    const init = async () => {
      await loadSections({
        areaId: areaId.value,
        entity
      });
      currentLandId.value = landId.value;
      filterObjects.value.parent_id = districtId.value;

      currentPolygon();
      geoObjects();
      lastEntity.value = entity;
    };

    // loadObjects({
    //   areaId: areaId.value,
    //   districtId: districtId.value,
    //   year: year.value,
    //   statusId: statusId.value
    // });

    loadDistricts({ areaId: areaId.value });

    const closeForm = ob => {
      isOpenForm.value = 0;
      //router.go(to);

      let params_ = {
        districtId: districtId.value,
        landId: currentLandId.value,
        legend: route.query.legend
      };

      if (!districtId.value) {
        params_.districtsAll = 1;
      }

      router.push({
        path: route.path,
        query: params_
      });

      if (ob.guid) {
        setTimeout(() => {
          isObjectGuid.value = ob.guid;
        }, 100);
      }
    };
    const targetAreaId = computed(() => {
      const area = getAreaById(areaId.value);
      return area?.target_area_id || area?.area_id || null;
    });
    const openForm = async () => {
      if (districtId.value != null) {
        dataParams.value.district = {
          id: districtId.value
        };
      }
      if (dataParams.value.coords == undefined) {
        dataParams.value.coords = mapState.value.center;
      }

      dataParams.value.area_id = targetAreaId.value;
      isOpenForm.value = 1;
    };

    const openObjectMap = obj => {
      router
        .push({
          path: route.path,
          query: { ...route.query }
        })
        .then(() => {
          object.value = obj;
          if (object.value.guid != null) {
            new Promise(resolve => {
              landId.value = object.value.property_type_id;
              currentLandId.value = object.value.property_type_id;
              resolve();
            }).then(() => {
              setTimeout(() => {
                isObjectGuid.value = object.value.guid;
              }, 100);
            });
          }
        });
    };
    /*
    const isObjectCreate = computed({
      get() {
        return route.query.create || null;
      },
      set() {
        openForm();
      }
    });
    if (isObjectCreate.value) {
      isObjectCreate.value = 1;
    }
    */

    const filtersMap = val => {
      if (val == undefined) {
        return;
      }
      if (val == 'filter') {
        isOpenFilters.value = 1;
        return;
      }

      if (val.property_type_id == null && val.violation_type_id == null) {
        router.push({
          path: route.path,
          query: {
            ...route.query,
            districtId: val.id
          }
        });
        defaultValues.value.DISTRICT_ID = val.id;
      } else if (val.violation_type_id == null) {
        router.push({
          path: route.path,
          query: {
            ...route.query,
            property_type_id: val.property_type_id
          }
        });
      } else {
        if (!('removed' in val)) {
          router.push({
            path: route.path,
            query: {
              ...route.query,
              property_type_id: val.property_type_id,
              violation_type_id: val.violation_type_id
            }
          });
        }
      }

      if ('removed' in val) {
        filterObjects.value.removed = val.removed;
      } else {
        filterObjects.value = {
          parent_id: val.id,
          property_type_id: val.property_type_id,
          violation_type_id: val.violation_type_id
        };
      }

      currentPolygon();
      geoObjects();
    };

    const applyFilter = async val => {
      if (val.areaId != 41) {
        val.districtId = val.areaId;
      }
      defaultValues.value.DISTRICT_ID = val.districtId;

      filterObjects.value = {
        parent_id: val.districtId,
        property_type_id: null,
        violation_type_id: null
      };
      currentPolygon();
      geoObjects();

      loadSections({
        entity,
        areaId: val.areaId,
        districtId: val.districtId,
        year: val.year
      });
    };

    const addPoint = ev => {
      mapState.value.lastCenter = ev.get('coords');
      mapState.value.zoom = ev.MAP.zoom;
      object.value = {};
      dataParams.value.coords = ev.get('coords');
    };

    const onClickPolygon = event => {
      // const id = ev.get('objectId');
      const objectId = event.get('objectId');
      const objects = event.get('currentTarget');
      const object = objects.getById(objectId);
      const areaId = object.properties.areaId;
      router
        .push({
          path: route.path,
          query: {
            ...route.query,
            // districtId: id
            districtId: areaId
          }
        })
        .then(() => {
          defaultValues.value.DISTRICT_ID = areaId;
          filterObjects.value = {
            parent_id: areaId,
            property_type_id: null,
            violation_type_id: null
          };
          currentPolygon();

          geoObjects();
        });
    };

    const onClickPoint = ev => {
      mapState.value.center = ev.get('coords');
      mapState.value.zoom = ev.MAP.zoom;
      mapState.value.polygon = true;

      const objectManager = ev.get('currentTarget').getObjectManager();

      const id = ev.get('objectId');
      object.value = objectManager.objects.getById(id).properties.data;

      if (object.value.guid != null) {
        new Promise(resolve => {
          landId.value = object.value.property_type_id;
          resolve();
        }).then(() => {
          setTimeout(() => {
            currentLandId.value = object.value.property_type_id;
            isObjectGuid.value = object.value.guid;
          }, 100);
        });
      }
    };

    const filterObjects = ref({
      violation_type_id: null,
      property_type_id: null,
      parent_id: null
    });

    const itemsCurrent = ref([]);

    const itemsGeoObjects = ref([]);

    const geoObjects = async () => {
      await loadSections({
        areaId: areaId.value,
        districtId: districtId.value,
        entity
      }).then(() => {
        itemsGeoObjects.value = mapHelpers
          .filterGeoObjects(tree.value, filterObjects.value)
          .reduce(
            (result, item, id) => {
              if (
                !item.guid ||
                (((!item.geo_type && entity != 'asot') || !item.geo_polygon) &&
                  item.violation_type_id != logic.PROPERTY_TYPE_LEGAL)
              ) {
                return result;
              }
              if (
                filterObjects.value.violation_type_id &&
                filterObjects.value.property_type_id &&
                (item.violation_type_id !==
                  filterObjects.value.violation_type_id ||
                  item.property_type_id !==
                    filterObjects.value.property_type_id)
              ) {
                return result;
              }

              if (
                item.property_type_id === logic.PROPERTY_TYPE_LEGAL &&
                logic.codes_violation_type_legal_before.includes(
                  item.violation_type_id
                )
              ) {
                result.legal_before.push(
                  defaultMapValues.point(id, item, entity)
                );
              } else if (
                item.property_type_id === logic.PROPERTY_TYPE_LEGAL &&
                logic.codes_violation_type_legal_after.includes(
                  item.violation_type_id
                )
              ) {
                result.legal_after.push(
                  defaultMapValues.point(id, item, entity)
                );
              } else {
                result.unlegal.push(defaultMapValues.point(id, item, entity));
              }

              return result;
            },
            { legal_before: [], legal_after: [], unlegal: [] }
          );
      });
    };

    const currentPolygon = async () => {
      await loadAreas(entity);

      if (!districtId.value || areaId.value == -1) {
        //filterObjects
        currentDistrict.value = areas.value.filter(
          el => el.area_parent_id == areaId.value || el.area_id == areaId.value
        );
      } else {
        currentDistrict.value = areas.value.filter(
          el => el.area_id == filterObjects.value.parent_id
        );
      }
      let poly = [];

      if (currentDistrict.value.length) {
        currentDistrict.value.forEach(el => {
          const coord = JSON.parse(el.area_polygon);
          const coordList =
            el.area_geo_type === 'multipolygon' ? coord : [coord];
          coordList.forEach(coord => {
            poly.push({
              polygon: coord,
              id: el.area_id,
              center: [el.area_lon, el.area_lat]
            });
          });
        });
      } else {
        currentDistrict.value = null;
      }

      if (!currentDistrict.value) return [];

      let data = [];
      poly.forEach(el => {
        mapState.value.center = el.center;
        if (el.id != 41) {
          mapState.value.zoom = 10;
        } else if (el.id == -1) {
          mapState.value.zoom = 6;
        }
        data.push(
          defaultMapValues.polygon({
            // id: el.id,
            id: Math.random(),
            geometry: {
              type: 'Polygon',
              coordinates: el.polygon
            },
            properties: {
              areaId: el.id
            }
          })
        );
      });
      itemsCurrent.value = data;

      if (areaId.value == -1) {
        mapState.value.zoom = 6;
      }
    };

    init();

    return {
      currentLandId,
      closeCard,
      isObjectGuid,
      closeForm,
      districtId,
      mapState,
      addPoint,
      dataParams,
      editObj,
      isOpenFilters,

      isOpenForm,
      isOpenLegend,
      isOpenSearch,
      filtersMap,
      areaId,
      openObjectMap,
      objects,
      tree: legendList,
      applyFilter,
      object,

      itemsCurrent,
      itemsGeoObjects,
      currentPolygon,
      onClickPoint,
      onClickPolygon,
      entity,
      loadingAreas,
      loadingObjects,
      loadingDistricts,
      loadingSections
    };
  }
};
</script>

<style lang="scss"></style>
