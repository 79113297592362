import useAreas from '@/composition/map/useAreas';

export default {
  filterGeoObjects(
    data,
    params = {
      violation_type_id: null,
      property_type_id: null,
      parent_id: null,
      removed: null
    }
  ) {
    const { areas } = useAreas();
    let valid = (el, f) => {
      if (el != null) return f();
      return false;
    };

    let filtered = data;

    if (params['removed']) {
      let index = null;
      filtered.find((element, i) => {
        if (element.guid == params['removed']) {
          index = i;
          return true;
        }
      });
      filtered.splice(index, 1);
    }

    if (params['violation_type_id']) {
      filtered = filtered.filter(el =>
        valid(el.violation_type_id, () => {
          return el.violation_type_id == params['violation_type_id'];
        })
      );
    }
    if (params['property_type_id']) {
      filtered = filtered.filter(
        el =>
          valid(el.property_type_id, () => {
            return el.property_type_id == params['property_type_id'];
          }) != false
      );
    }

    if (params['parent_id']) {
      if (params['parent_id'] == -1) {
        let areas_ = areas.value.filter(el => el.area_parent_id == -1);
        let datas = [];
        let arr = [];
        areas_.forEach(ell => {
          let b = data.filter(
            el =>
              valid(el['parent_id'], () => {
                return el.parent_id.indexOf(ell['area_id']) != -1;
              }) != false
          );

          if (b.length) {
            arr = [...arr, ...b];
          }
        });
        if (arr.length) {
          arr.forEach(dis => {
            datas = [
              ...datas,
              ...data.filter(
                el =>
                  valid(el['parent_id'], () => {
                    return el.parent_id.indexOf(dis['id']) != -1;
                  }) != false
              )
            ];
          });
        }

        filtered = datas;
      } else {
        filtered = filtered.filter(
          el =>
            valid(el.parent_id, () => {
              return el.parent_id.indexOf(params['parent_id']) != -1;
            }) != false
        );
      }
    }

    return filtered;
  }
};
